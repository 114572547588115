"use client";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Image from "next/image";
import { useSearchParams, usePathname } from "next/navigation";
import {
  clientId,
  redirectUri,
  discoveryDocument,
  storeLoginResponse,
} from "@/app/_components/reducers/loginSlice";
import { AppDispatch } from "@/app/_components/reducers/store";
import { ChevronDoubleRight } from "@/app/_components/ui/graphics/ChevronDoubleRight";
import { Button } from "../ui/buttons/button";
import CloudfrontLogin from "./CloudfrontLogin";
import {
  COGNITO_ENDPOINT_EXTERNAL,
  COGNITO_CLIENT_ID_EXTERNAL,
} from "@/app/_consts/endpoints";
import { cn } from "@/app/_utils/ui";

const Login = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [showLogin, setShowLogin] = useState(false);
  const [errorStr, setErrorStr] = useState<string | null>(null);
  const searchParams = useSearchParams();
  const pathname = usePathname();

  useEffect(() => {
    const attemptedLogin = localStorage.getItem("attemptedLogin");
    const code = searchParams.get("code");

    if (code) {
      // Handle the authentication process
      handleAuth(pathname, dispatch);
    } else if (!attemptedLogin) {
      // Attempt to sign in automatically once
      localStorage.setItem("attemptedLogin", "true");
      handleSignIn();
    } else {
      // Show the login button since we've already tried once
      setShowLogin(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const handleAuth = async (url: string, dispatch: AppDispatch) => {
    try {
      const urlParams = new URLSearchParams(url);
      const code = searchParams.get("code");

      // Check if there is an error in the URL
      const error = urlParams.get("error_description");
      if (error) {
        setErrorStr(decodeURIComponent(error).replaceAll("+", " "));
        return;
      }

      if (code) {
        // Prepare the request for token exchange
        const body = new URLSearchParams();
        body.append("grant_type", "authorization_code");
        body.append("code", code);
        body.append("redirect_uri", redirectUri);
        body.append("client_id", clientId);

        // Send a request to exchange the code for tokens
        const response = await fetch(discoveryDocument.tokenEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: body,
        });

        const tokenResponse = await response.json();

        // Dispatch the token response to the store
        dispatch(storeLoginResponse(tokenResponse));
        if (tokenResponse.access_token) {
          // Remove the code parameter from the URL before reloading
          urlParams.delete("code");
          window.location.href =
            window.location.pathname +
            "?" +
            urlParams.toString() +
            window.location.hash;
        }
      }
    } catch (error) {
      setErrorStr("An error occurred during the authentication process.");
    }
  };

  const handleSignIn = () => {
    // Redirect to the OAuth provider
    localStorage.setItem("redirectAfterLogin", pathname);
    window.location.href = `${COGNITO_ENDPOINT_EXTERNAL}/oauth2/authorize?identity_provider=Okta&redirect_uri=${window.location.origin}/loginredirect&response_type=CODE&client_id=${COGNITO_CLIENT_ID_EXTERNAL}&scope=aws.cognito.signin.user.admin email openid phone profile`;
  };

  return (
    <div
      style={{ transitionDelay: "1500ms" }}
      className={cn(
        "flex flex-col justify-center items-center h-screen w-screen px-8 transition-all duration-500 ease-in-out",
        showLogin ? "opacity-100" : "opacity-0 pointer-events-none",
      )}
    >
      <Image
        src="/assets/images/vegmanifestologo_home_mobile.svg"
        alt="Veg Manifesto Logo"
        width={600}
        height={108}
        className="sm:hidden"
      />
      <Image
        priority
        src="/assets/images/vegmanifestologo_home_web.svg"
        alt="Veg Manifesto Logo"
        width={600}
        height={108}
        className="hidden sm:block"
      />
      <Button onClick={handleSignIn} id={"login_Button"} className="mt-6 mb-24">
        <ChevronDoubleRight className="w-5 h-5" />
        Sign in With Okta
      </Button>
      {errorStr && <p>{errorStr}</p>}
      <div className="absolute bottom-8 left-0 w-full text-center">
        <CloudfrontLogin />
      </div>
    </div>
  );
};

export default Login;
